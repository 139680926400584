import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CIcon } from '@coreui/icons-react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { latLngBounds, icon, LatLng } from 'leaflet'; // Importa LatLng
// import { useMap } from 'react-leaflet/hooks';
import { useMapEvents } from 'react-leaflet/hooks';
import "leaflet/dist/leaflet.css";
import '../../index.css';
import Widgets from '../widgets/Widgets';
import * as XLSX from 'xlsx';
import Tooltip from "../Tooltip/tooltip";

import {
  CCard,
  CButton,
  CTab,
  CTabs,
  CTabList,
  CTabContent,
  CTabPanel,
  CFormInput,
  CFormLabel,
  CCol,
  CRow,
  CCardBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormSelect,
  CModalContent,
  CTooltip,
  CFormTextarea,
  CToast,
  CToastBody,
  CToastClose,
  CSpinner,
  CAlertLink,
  CCardImage,
  CPagination,
  CPaginationItem,
} from '@coreui/react';

import {
  cilMagnifyingGlass,
  cilMap,
  cilOptions,
  cilCloudDownload,
  cilCompress,
} from '@coreui/icons'

// import marketIcon from '../../assets/brand/turn-left.png';
import iconArrowDown from '../../assets/images/arrow-down.png'
import marketIcon2 from '../../assets/brand/turn-right.png';
import marketRuta from '../../assets/brand/ruta.png';
import marketSeñal from '../../assets/brand/señal.png';
import marketMapaSeñal from '../../assets/brand/mapa-señal.png';
import marketCartel from '../../assets/brand/cartel.png';
import circle from '../../assets/images/circle.png';
import circleAzul from '../../assets/images/circle-azul.png';
import circleVerde from '../../assets/images/circle-verde.png';

function UpdateMapCentre({ mapCentre }) {
  const map = useMap();
  map.panTo(mapCentre);
  return null;
}

export default function DetailIndex({ profile, setProfile }) {
  // const [selectedDate, setSelectedDate] = useState(null); // Inicializar con la fecha 
  // const [selectedDate2, setSelectedDate2] = useState(null); // Inicializar con la fecha2
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [nameProyectoDetalles, setNameProyectoDetalles] = useState(null);
  const [senaleticaReclasificada, setSenaleticaReclasificada] = useState(null);
  const [descripcionReclasificada, setDescripcionReclasificada] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [severidadReclasificada, setSeveridadReclasificada] = useState(null);
  const [itemIdSenal, setItemIdSenal] = useState(null);
  const [tipoSenal, setTipoSenal] = useState(null);
  const [senaleticaDetectada, setSenaleticaDetectada] = useState(null);
  const [descripcionDetectada, setDescripcionDetectada] = useState(null);
  const [observacionesSenal, setObservacionesSenal] = useState(null);
  const [latitudSenal, setLatitudSenal] = useState(null);
  const [longitudSenal, setLongitudSenal] = useState(null);
  const [carreteraAsociadaSenal, setCarreteraAsociadaSenal] = useState(null);
  const [fechaDeteccionSenal, setFechaDeteccionSenal] = useState(null);
  const [puntokmSenal, setPuntokmSenal] = useState(null);
  const [sentidoSenal, setSentidoSenal] = useState(null);
  const [itemIdDano, setItemIdDano] = useState(null);
  const [tipoDano, setTipoDano] = useState(null);
  const [area, setArea] = useState(null);
  const [severidad, setSeveridad] = useState(null);
  const [observaciones, setObservaciones] = useState(null);
  const [latitudDano, setLatitudDano] = useState(null);
  const [longitudDano, setLongitudDano] = useState(null);
  const [carreteraAsociadaDano, setCarreteraAsociadaDano] = useState(null);
  const [fechaDeteccionDano, setfechaDeteccionDano] = useState(null);
  const [PuntokmDano, setPuntokmDano] = useState(null);
  const [sentidoDano, setsentidoDano] = useState(null);
  const [nombreProyecto1, setNombreProyecto1] = useState(null);
  const [fechaCreacion1, setFechaCreacion1] = useState(null);
  const [fechaInicio1, setFechaInicio1] = useState(null);
  const [fechaFin1, setFechaFin1] = useState(null);
  const [cliente1, setCliente1] = useState(null);
  const [operador1, setOperador1] = useState(null);
  const [descripcion1, setDescripcion1] = useState(null);
  const [buscarProyecto, setBuscarProyecto] = useState(null);
  const [activeKey, setActiveKey] = useState(1); // Estado para el tab activo
  const [buscarTexto, setBuscarTexto] = useState(''); // Un único estado de búsqueda
  const [dataProyecto, setDataProyecto] = useState(null);
  const [idProyecto, setidProyecto] = useState(null);
  const [urlFrame, setUrlFrame] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [markersData, setMarkersData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageFisuras, setCurrentPageFisuras] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50); // O la cantidad que desees
  const [itemsPerPageFisuras, setItemsPerPageFisuras] = useState(50); // O la cantidad que desees
  const navigate = useNavigate();
  // const [scrollOffset, setScrollOffset] = useState(0);
  const tableRef = useRef(null); // Referencia para la tabla
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false); // Estado para el mapa
  const [mapCenter, setMapCenter] = useState(null); // Inicializa mapCenter con null
  const [map, setMap] = useState(null); // Estado para almacenar el objeto Map
  const { id } = useParams();
  const [currentItems, setCurrentItems] = useState([]);
  const [currentItemsFisuras, setCurrentItemsFisuras] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalPagesFisuras, setTotalPagesFisuras] = useState([]);
  const [confirmar, setConfirmar] = useState(true)
  const buttonRef = useRef(null);
  const camposBusquedaSennal = ['tipo_especifico', 'tipo', 'carretera_asociada'];
  const camposBusqueda = ['severidad', 'tipo', 'area', 'frame']; // Incluye todos los campos que quieras buscar

  const [WorkoutsData, setWorkoutsData] = useState([]); // Ahora WorkoutsData es un estado

  // Paginación
  const [visiblePages, setVisiblePages] = useState([1]); // Páginas visibles inicialmente
  const [visiblePagesFisuras, setVisiblePagesFisuras] = useState([1]); // Páginas visibles inicialmente
  const maxVisiblePages = 5; // Número máximo de páginas visibles a la vez
  const maxVisiblePagesFisuras = 5; // Número máximo de páginas visibles a la vez

  const [showMarkers, setShowMarkers] = useState(true); // Estado para controlar la visibilidad de los marcadores
  // const position1 = [-34.61315, -58.37723]
  // const position = [-34.641444444444446, -58.351305555555555]

  const position = [-32.124, -63.4461]
  // const position2 = [-34.61615, -58.39723]

  const markers = [
    {
      geocode: [-34.61315, -58.37723],
      popup: "Hello"
    }
  ];
  
  const lines = [
    "La busqueda es por los campos:",
    "1. Señal = tipo, señaletica, carretera asociada",
    "2. Pavimento = tipo, severidad, area",
  ];

  // Crea los iconos con los colores que quieres
  const blueIcon = new icon({
    iconUrl: circleAzul,
    iconSize: [16, 16],
    iconAnchor: [8, 8], // Ajusta si el icono no se centra
    popupAnchor: [0, -8], // Ajusta si el popup se ubica mal
    className: 'blue-marker' // Agrega una clase CSS para personalizar
  });

  const greenIcon = new icon({
    iconUrl: circleVerde,
    iconSize: [16, 16],
    iconAnchor: [8, 8],
    popupAnchor: [0, -8],
    className: 'green-marker'
  });

  const redIcon = new icon({
    iconUrl: circle,
    iconSize: [16, 16],
    iconAnchor: [8, 8],
    popupAnchor: [0, -8],
    className: 'red-marker'
  });

  const customIcon = new icon({
    iconUrl: circle,
    iconSize: [16, 16] //size icon
    // iconSIze: [1.5,1.5]
  });
  const customIcon2 = new icon({
    iconUrl: marketIcon2,
    iconSize: [16, 16] //size icon
    // iconSIze: [1.5,1.5]
  });

  // Función para obtener el icono según el tipo de señal
  const getIcon = (type) => {
    switch (type) {
      case 'R': // Señal Vertical: Reglamentaria
      case 'P': // Señal Vertical: Prioritaria
      case 'I': // Señal Vertical: Informativa
      case 'I10': // Señal Vertical: Mojón Kilométrico
      case 'I_SOS': // Señal Vertical: Poste SOS, Emergencia 140
      case 'I_ES': // Señal Vertical: Estructuras (Pórticos - ménsulas)
      case 'L': // Señal Vertical: Iluminacion 
        return redIcon; // Azul para señal vertical

      case 'H2': // Señal Horizontal: Linea de carril (blanca discontinua)
      case 'H3': // Señal Horizontal: Linea de Borde (blanca o amarilla continua)
      case 'H9': // Señal Horizontal: Flecha Simple, Curvas y Combinadas 
      case 'H12': // Señal Horizontal: Pictoramas.(Ovalo de velocidad, Rombo, Ceda el paso, Vel, Niebla, Max, Est, etc)      
        return blueIcon; // Verde para señal horizontal

      case 'Def': // Señal Vertical: Elementos de seguridad: Defensas metálicas, New Jersey
      case 'bache': // Fisura: Baches
        return greenIcon; // Rojo para bache

      default:
        return redIcon; // Rojo por defecto si no se define el tipo
    }
  };

  const handleCloseToast = (element) => {
    setShowSuccessToast(false);
    // navigate(`/detail-project/${id}`);
    window.location.href = `/detail-project/${id}`; 
  }
  const handleCloseToastError = (element) => {
    setShowErrorToast(false);
  }

  const handleVerImagen = useCallback((url_frame) => {
    // console.log('valor: ', url_frame)
    setUrlFrame(url_frame);
    setVisible3(true);
  }, []);

  const handleMarkerClick = (markerData) => {
    // Actualiza el estado 'data' con la información del marcador
    setData([
      // { value: markerData.confianza, label: markerData.area, tipo: markerData.tipo, id_frame: markerData.id_frame },
      { tipo: markerData.tipo, id_frame: markerData.id_frame, url_frame: markerData.url_frame, tipo_camara: markerData.tipo_camara, name_video: markerData.name_video },
      // Puedes agregar más datos aquí si es necesario
    ]);
    centerMapOnFirstMarkerClick(markerData.latitud, markerData.longitud)
  };

  const handleUpdateDanno = async () => {
    // e.preventDefault();
    // if (fechaRelevamiento != null) {
    //   fechaRelevamiento = selectedDate3.toISOString().slice(0, 19).replace('T', ' ');
    // }

    const formData = new FormData();
    formData.append('itemIdDano', itemIdDano);
    formData.append('area', area);
    formData.append('tipoDano', tipoDano);
    formData.append('tipoReclasificacionDano', severidadReclasificada);
    formData.append('severidad', severidad);
    formData.append('observaciones', observaciones);
    formData.append('latitudDano', latitudDano);
    formData.append('longitudDano', longitudDano);

    // formData.append('estadoDrenaje', CarreteraAsociadaDan);
    // formData.append('anchoCalzada', sentidoDano);


    // formData.append('estadoBordeCalzada', estadoBordeCalzada);
    // formData.append('tipoPavimento', tipoPavimento);

    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      // console.log(storedName)
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      // console.log(token)
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/update/detection-damage`, formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      });

      // console.log('Respuesta del servidor:', response.data);
      setVisible2(false);
      setShowSuccessToast(true);
      navigate(`/detail-project/${id}`);
      // setShowSuccessToast(true); 
      // navigate('/dashboard'); // Redirige si la data se obtiene correctamente
    } catch (error) {
      if (error.response.status === 401) {
        // Token inválido o expirado
        console.error("Error 401: No autorizado");
        // Redirige al inicio de sesión, limpia el token, etc.
        localStorage.removeItem('token');
        window.location.href = '/login'; // Ajusta la ruta si es necesario 
        console.error('Error al subir el video:', error);
      }
      setShowErrorToast(true)
    } finally {
      setIsLoading(false); // Ocultar el spinner al finalizar la carga
    }
  };

  const handleUpdateSennal = async () => {
    // e.preventDefault();
    // if (fechaRelevamiento != null) {
    //   fechaRelevamiento = selectedDate3.toISOString().slice(0, 19).replace('T', ' ');
    // }

    const formData = new FormData();
    formData.append('itemIdSenal', itemIdSenal);
    formData.append('tipoSenal', tipoSenal);
    formData.append('senaleticaDetectada', senaleticaDetectada);
    formData.append('senaleticaReclasificada', senaleticaReclasificada);
    formData.append('descripcionReclasificada', descripcionReclasificada);
    // formData.append('severidad', severidad);
    formData.append('observacionesSenal', observacionesSenal);
    formData.append('latitudSenal', latitudSenal);
    formData.append('longitudSenal', longitudSenal);

    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      // console.log(storedName)
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      // console.log(token)
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/update/detection-sign`, formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      });

      // console.log('Respuesta del servidor:', response.data);
      setVisible(false);
      setShowSuccessToast(true);
      navigate(`/detail-project/${id}`);
      // setShowSuccessToast(true); 
      // navigate('/dashboard'); // Redirige si la data se obtiene correctamente
    } catch (error) {
      if (error.response.status === 401) {
        // Token inválido o expirado
        console.error("Error 401: No autorizado");
        // Redirige al inicio de sesión, limpia el token, etc.
        localStorage.removeItem('token');
        window.location.href = '/login'; // Ajusta la ruta si es necesario 
        console.error('Error al subir el video:', error);
      }
      setShowErrorToast(true)
    } finally {
      setIsLoading(false); // Ocultar el spinner al finalizar la carga
    }
  };

  useEffect(() => {
    setActiveKey(1);
    setVisible(false)
    setVisible2(false)
    setConfirmar(true)
    if (confirmar && buttonRef.current) {
      // console.log(buttonRef.current)..
      buttonRef.current.focus(); // Asignar el foco al botón
    }
    // Este useEffect se ejecutará siempre que cambie `id` y asegurará que los datos se recarguen
  }, [id]);

  useEffect(() => {
    // console.log('jsonData')
    setActiveKey(1);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // console.log('jsonData222')
        const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
        const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
        // console.log(token)
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/one-project/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        // console.log('jsonData333')
        if (!response.ok) {
          if (response.status === 401) {
            // Token inválido o expirado
            console.error("Error 401: No autorizado");
            // Redirige al inicio de sesión, limpia el token, etc.
            localStorage.removeItem('token');
            window.location.href = '/login'; // Ajusta la ruta si es necesario 
          } else {
            // Otros errores
            throw new Error(`Error ${response.status}: ${response.statusText}`);
          }
        }
        // console.log('jsonData555')
        const jsonData = await response.json();
        setNameProyectoDetalles(jsonData[0].nombre);
        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }

    };
    fetchData();
  }, []);

  useEffect(() => {
    setActiveKey(1);
    if (dataProyecto) {
      // console.log('Data proyecto nuevo:', dataProyecto.fisuras);
      // Carga información del proyecto
      setidProyecto(dataProyecto.proyecto[0].id);
      setNombreProyecto1(dataProyecto.proyecto[0].nombre);
      setCliente1(dataProyecto.proyecto[0].cliente)
      setOperador1(dataProyecto.proyecto[0].operador)
      setDescripcion1(dataProyecto.proyecto[0].descripcion)
      setFechaCreacion1(dataProyecto.proyecto[0].fecha_creacion);
      setFechaInicio1(dataProyecto.proyecto[0].fecha_inicio);
      setFechaFin1(dataProyecto.proyecto[0].fecha_fin);

      setDataProyecto(dataProyecto);
      setCurrentItems(dataProyecto.senales.slice(currentPage, itemsPerPage))
      setCurrentItemsFisuras(dataProyecto.fisuras.slice(currentPageFisuras, itemsPerPageFisuras))
      setTotalPages(Math.ceil(dataProyecto.senales.length / itemsPerPage))
      setTotalPagesFisuras(Math.ceil(dataProyecto.fisuras.length / itemsPerPageFisuras))

    }

  }, [dataProyecto]);

  const centerMapOnFirstMarker = () => {
    // const dataProyecto2 = JSON.parse(localStorage.getItem('data-proyecto2'));
    const dataProyecto2 = dataProyecto

    setChangedCoords({
      lat: dataProyecto2.senales[0].latitud,
      lng: dataProyecto2.senales[0].longitud,
    });
    localStorage.removeItem('data-proyecto2');
  };

  const centerMapOnFirstMarkerClick = (latitud, longitud) => {
    setChangedCoords({
      lat: latitud,
      lng: longitud,
    });
  };

  // Actualizar currentItems cuando cambia currentPage
  useEffect(() => {
    if (dataProyecto) {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentItems(dataProyecto.senales.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [currentPage]);

  // Actualizar currentItems cuando cambia currentPage
  useEffect(() => {
    if (dataProyecto) {
      const indexOfLastItemFisuras = currentPageFisuras * itemsPerPageFisuras;
      const indexOfFirstItemFisuras = indexOfLastItemFisuras - itemsPerPageFisuras;
      setCurrentItemsFisuras(dataProyecto.fisuras.slice(indexOfFirstItemFisuras, indexOfLastItemFisuras));
    }
  }, [currentPageFisuras]);

  useEffect(() => {
    if (markersData) {
      // console.log('Data proyecto nuevo:', dataProyecto.fisuras);
      setMarkersData(markersData);

    }
  }, [markersData]);


  const [initialCoords, setInitialCoords] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [changedCoords, setChangedCoords] = useState({
    lat: -34.641444444444446,
    lng: -58.351305555555555,
  });


  const handleEditDanno = (index, id) => {
    if (dataProyecto) {
      // console.log('index ', index)
      // console.log('id ', id)
      setItemIdDano(id); // Almacena el ID antes de abrir el modal
      setArea(dataProyecto.fisuras[index].area);
      setTipoDano(dataProyecto.fisuras[index].tipo);
      setSeveridad(dataProyecto.fisuras[index].severidad);
      setSeveridadReclasificada(dataProyecto.fisuras[index].tipo_reclasificacion)
      setObservaciones(dataProyecto.fisuras[index].descripcion);
      setLatitudDano(dataProyecto.fisuras[index].latitud);
      setLongitudDano(dataProyecto.fisuras[index].longitud);
      setLongitudDano(dataProyecto.fisuras[index].longitud);
      setCarreteraAsociadaDano(dataProyecto.fisuras[index].carretera_asociada);
      setSeveridadReclasificada(dataProyecto.fisuras[index].tipo_reclasificacion);
      setsentidoDano(dataProyecto.fisuras[index].sentido_carril);
      setfechaDeteccionDano(dataProyecto.fisuras[index].fecha_captura);
      // setPuntokmDano(dataProyecto.fisuras[index].puntokm);


      setVisible2(true);
    }
  };

  const handleEditSennal = (index, id) => {
    if (dataProyecto) {
      // console.log('index ', index)
      // console.log('id ', id)
      setItemIdSenal(id); // Almacena el ID antes de abrir el modal
      // setArea(dataProyecto.fisuras[index].area);
      setTipoSenal(dataProyecto.senales[index].tipo);
      setSenaleticaDetectada(dataProyecto.senales[index].tipo_especifico)
      setSenaleticaReclasificada(dataProyecto.senales[index].tipo_reclasificacion)
      setSeveridad(dataProyecto.senales[index].severidad);
      setObservacionesSenal(dataProyecto.senales[index].descripcion);
      setDescripcionReclasificada(dataProyecto.senales[index].descripcion_reclasificacion);
      setLatitudSenal(dataProyecto.senales[index].latitud);
      setLongitudSenal(dataProyecto.senales[index].longitud);
      // setSeveridadReclasificada(dataProyecto.fisuras[index].longitud);
      setCarreteraAsociadaSenal(dataProyecto.senales[index].carretera_asociada);
      setFechaDeteccionSenal(dataProyecto.senales[index].fecha_captura);
      // setPuntokmSenal(dataProyecto.fisuras[index].puntokm);
      setSentidoSenal(dataProyecto.senales[index].sentido_carril);

      setVisible(true);
    }
  };

  const handleDownload = () => {
    if (dataProyecto) {
      // Crea un objeto de hoja de cálculo para "Fisuras"
      const worksheetFisuras = XLSX.utils.json_to_sheet(dataProyecto.fisuras);

      // Crea un objeto de hoja de cálculo para "Senales"
      const worksheetSenales = XLSX.utils.json_to_sheet(dataProyecto.senales);

      // Crea un libro de trabajo
      const workbook = XLSX.utils.book_new();

      // Agrega la hoja de cálculo de "Fisuras" al libro de trabajo
      XLSX.utils.book_append_sheet(workbook, worksheetFisuras, 'Fisuras');

      // Agrega la hoja de cálculo de "Senales" al libro de trabajo
      XLSX.utils.book_append_sheet(workbook, worksheetSenales, 'Senales'); // Nombre de la pestaña

      // Crea un buffer de archivo
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Crea un enlace de descarga
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      downloadLink.download = 'data-proyecto.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

  };

  const handlefind = () => {
    setConfirmar(false)
    const fetchData = async () => {
      setIsLoading(true); // Muestra un indicador de carga mientras se realiza la solicitud

      try {
        const tokenData = localStorage.getItem('token'); // Obtén el token desde el localStorage
        const token = tokenData ? JSON.parse(tokenData).token : null; // Parsear el token JSON

        if (!token) {
          throw new Error('Token no disponible en localStorage'); // Manejar el caso en el que no haya token
        }

        // Realiza la solicitud a la API con el token en los headers
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/detail-project/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        // Si la respuesta no es correcta, lanza un error
        if (!response.ok) {
          if (response.status === 401) {
            // Token inválido o expirado
            console.error("Error 401: No autorizado");
            // Redirige al inicio de sesión, limpia el token, etc.
            localStorage.removeItem('token');
            window.location.href = '/login'; // Ajusta la ruta si es necesario 
          } else {
            // Otros errores
            throw new Error(`Error ${response.status}: ${response.statusText}`);
          }
        }

        // Obtén los datos JSON de la respuesta
        const jsonData = await response.json();
        // console.log('Datos JSON recibidos:', jsonData); // Imprime los datos recibidos

        // Actualiza el estado de dataProyecto con los datos recibidos
        setDataProyecto(jsonData);
        setMarkersData(jsonData);

        // Guarda los datos en el localStorage si es necesario
        localStorage.setItem('data-proyecto2', JSON.stringify(jsonData));

        centerMapOnFirstMarker();
      } catch (error) {
        console.error('Error durante la solicitud:', error); // Manejo de errores
        setError(error); // Guarda el error en el estado para mostrarlo en la interfaz
      } finally {
        setIsLoading(false); // Detén el indicador de carga

      }
    };

    fetchData(); // Llama a la función fetchData cuando el componente se monte o cambie el id

  };

  // Función para descargar la imagen
  const handleDownloadImage = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'imagen_descargada.jpg'; // Puedes cambiar el nombre del archivo aquí
    link.click();
  };

  // Función para manejar el clic en un número de página
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);

    // Actualizar las páginas visibles
    const newVisiblePages = [pageNumber];
    for (let i = 1; i <= maxVisiblePages - 1; i++) {
      if (pageNumber - i > 0) {
        newVisiblePages.unshift(pageNumber - i);
      }
      if (pageNumber + i <= totalPages) {
        newVisiblePages.push(pageNumber + i);
      }
      if (newVisiblePages.length === maxVisiblePages) {
        break;
      }
    }
    setVisiblePages(newVisiblePages);
  };

  // Función para manejar el clic en un número de página
  const handlePageClickFisuras = (pageNumberFisuras) => {
    setCurrentPageFisuras(pageNumberFisuras);

    // Actualizar las páginas visibles
    const newVisiblePagesFisuras = [pageNumberFisuras];
    for (let i = 1; i <= maxVisiblePagesFisuras - 1; i++) {
      if (pageNumberFisuras - i > 0) {
        newVisiblePagesFisuras.unshift(pageNumberFisuras - i);
      }
      if (pageNumberFisuras + i <= totalPagesFisuras) {
        newVisiblePagesFisuras.push(pageNumberFisuras + i);
      }
      if (newVisiblePagesFisuras.length === maxVisiblePagesFisuras) {
        break;
      }
    }
    setVisiblePagesFisuras(newVisiblePagesFisuras);
  };


  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs='12' className='d-flex align-items-center contenedor-table-list-titulo'>

          <CIcon icon={cilMap} size="xxl" className="height-auto icon-background-orange" style={{ '--ci-primary-color': 'white' }} /><h5 className='margin-bottom-h5'><strong>Detalle de Proyecto</strong></h5>
        </CCol>
        <div className="grid-container"> {/* Contenedor principal con CSS Grid */}
          <div className="grid-item map-container" style={{ position: 'relative' }}> {/* Primera cuadrícula superior para el mapa */}
            {/* <CCard className="mb-4"> */}
            <CCard>
              <div ref={mapRef}>
                <MapContainer center={position} zoom={13} scrollWheelZoom={true} style={{ height: '300px' }} zoomAnimation={true}>
                  <TileLayer
                    attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <UpdateMapCentre mapCentre={new LatLng(changedCoords.lat, changedCoords.lng)} />
                  {markersData && markersData.senales &&
                    markersData.senales.map((markerData1) => (
                      <Marker
                        key={markerData1.id}
                        position={[parseFloat(markerData1.latitud), parseFloat(markerData1.longitud)]}
                        icon={getIcon(markerData1.tipo_especifico)} // Usa el icono correcto
                        eventHandlers={{
                          click: () => handleMarkerClick(markerData1)
                        }}
                      >
                        <Popup>
                          <div>
                            <strong>Tipo:</strong> {markerData1.tipo}<br />
                            <strong>Especificación:</strong> {markerData1.tipo_especifico}<br />
                            <strong>Latitud:</strong> {markerData1.latitud}<br />
                            <strong>Longitud:</strong> {markerData1.longitud}<br />
                            {/* Agrega otros campos según sea necesario */}
                          </div>
                        </Popup>
                      </Marker>
                    ))}
                </MapContainer>
              </div>
            </CCard>
          </div>

          <CCard className='video-container-card'>

            <Widgets data={data} />

          </CCard>
        </div>
        {/* <CButton className="mt-2" onClick={handleUpdateMap}>Actualizar Posición</CButton> */}
        {/* <CButton className="mt-2" onClick={handleUpdatePosition}>Actualizar Posición</CButton> */}
        <div className="grid-container-4-cards">
          <div className="card-4-cards">
            <div className="grid-container-3-4-cards">
              <CRow>
                <CCol xs={3} className="letra-contenido">
                  <CCard className='width-card-anotaciones'>
                    <CRow className='text-center'> {/* Agrega text-center a la fila */}
                      <CCol xs={1} className=''></CCol>
                      <CCol xs={10} className='rsd'>

                        <span className="letra-contenido-4"><img src={marketMapaSeñal} width={20} alt="Market Mapa Señal" /></span>
                        <span className='letra-contenido-4 ml-2'>Detecciones</span>

                      </CCol>
                      <CCol xs={1} className=''></CCol>
                    </CRow>
                    <CRow className='text-center'> {/* Agrega text-center a la fila */}
                      <CCol className='text-center rsd2'>
                        <span className="letra-contenido-4">{dataProyecto && dataProyecto.fisuras && dataProyecto.senales ? dataProyecto.fisuras.length + dataProyecto.senales.length : 0}</span>
                        <span className='fuente-letra-gris ml-2'> /Totales</span>
                      </CCol>
                    </CRow>
                  </CCard>
                </CCol>
                <CCol xs={3} className="letra-contenido">
                  <CCard className='width-card-anotaciones'><CRow>
                    <CCol className='text-center rsd'>
                      <img src={marketSeñal} width={20}></img><span className='letra-contenido-4 ml-2'>Señales Verticales</span>
                    </CCol>
                  </CRow>
                    <CRow>
                      <CCol className='text-center rsd2'>
                        <span className="letra-contenido-4">{dataProyecto && dataProyecto.cantidad_senales ? dataProyecto.cantidad_senales[0].senales_verticales : 0}</span> <span className='fuente-letra-gris ml-2'>/Totales</span>
                      </CCol>
                    </CRow></CCard></CCol>
                <CCol xs={3} className="letra-contenido">
                  <CCard className='width-card-anotaciones'>
                    <CRow>
                      <CCol className='text-center rsd'>
                        <img src={marketCartel} width={20}></img><span className='letra-contenido-4 ml-2'>Señales Horizontales</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className='text-center rsd2'>
                        <span className="letra-contenido-4">{dataProyecto && dataProyecto.cantidad_senales ? dataProyecto.cantidad_senales[1].senales_horizontales : 0}</span> <span className='fuente-letra-gris ml-2'>/Totales</span>
                      </CCol>
                    </CRow></CCard></CCol>
                <CCol xs={3} className="letra-contenido">
                  <CCard className='width-card-anotaciones'>
                    <CRow>
                      <CCol className='text-center rsd'>
                        <img src={marketRuta} width={20}></img><span className='letra-contenido-4 ml-2'>Daños de Pavimento</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className='text-center rsd2'>
                        <span className="letra-contenido-4">{dataProyecto && dataProyecto.fisuras ? dataProyecto.fisuras.length : 0}</span>  <span className='fuente-letra-gris ml-2'>/Totales</span>
                      </CCol>
                    </CRow>
                  </CCard>
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
        <div className="grid-container-2"> {/* Contenedor principal con CSS Grid */}
          <div className="grid-item map-container"> {/* Primera cuadrícula superior para el mapa */}
            <CCard className='video-container-card'>
              <div class="tab-list-container">

                <div class="inner-addon right-addon">
                  <CRow>
                    <CCol xs='10'>
                      <CIcon icon={cilMagnifyingGlass} size="xxl" className="glyphicon glyphicon-search" style={{ '--ci-primary-color': 'black' }} />
                      {/* <input type="text" class="form-control" placeholder="Buscar proyecto" /> */}
                      {/* <CFormLabel htmlFor="anchoCarril"></CFormLabel> */}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CFormInput
                          size="sm"
                          id="anchoCarril"
                          placeholder="Buscar Item"
                          style={{ width: '30%' }}
                          value={buscarTexto}
                          onChange={(e) => setBuscarTexto(e.target.value)}
                        />
                        <div className="">
                          <Tooltip text={
                            <div><span style={{ lineHeight: '0.3' }}>
                              {lines.map((line, index) => <p key={index}>{line}</p>)}</span>
                            </div>
                          }>
                            <span className="d-inline-block padding-tooltip" tabIndex={0}>
                              <CButton className="btn-round-small-find">?</CButton>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </CCol>

                    <CCol xs='2'>
                      <div className='display-flex-icon'>
                        <CButton className="px-8 btn-descargar-list" type="submit" onClick={handleDownload}><img src={iconArrowDown} alt="Selected Image" className="mr-2" style={{ width: '11px', height: '11px', objectFit: 'contain' }} /><span className='padding-separar-boton-descargar'>Descargar</span></CButton>
                      </div>
                    </CCol>
                  </CRow>
                </div>
                <CTabs activeItemKey={1} key={activeKey} activeKey={activeKey} onActiveKeyChange={setActiveKey}>
                  <CTabList variant="underline-border" className='tablist-map-component'>
                    <CTab className='asb asbd' aria-controls="home-tab-pane" itemKey={1}>Información de Proyecto</CTab>
                    <CTab className='asb' aria-controls="profile-tab-pane" itemKey={2}>Señales de Transito</CTab>
                    <CTab className='asb' aria-controls="profile-tab-pane" itemKey={3}>Daños de Pavimento</CTab>
                  </CTabList>
                  <CTabContent>
                    <CTabPanel className="py-3 tabpanel-aling-content" aria-labelledby="home-tab-pane" itemKey={1}>
                      <CRow>
                        <CCol xs='6'>

                          <CCard className='video-container-card'>
                            <CRow>

                              <CCol xs='12'>
                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-3">
                                        <h5>Proyecto ID {id}:</h5>
                                      </CTableDataCell>
                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-3">
                                        {/* <strong>Relevamiento Autopista</strong> */}
                                        <strong>{nombreProyecto1}</strong>
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* 17/08/2024 15:02:56 */}
                                        {fechaCreacion1}
                                      </CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                </CTable>

                              </CCol>

                            </CRow>
                            <hr className='quitar-margin-hr'></hr>
                            <CRow>

                              <CCol xs='12'>

                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                                        <strong>Fecha de inicio de ejecución:</strong>
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                                        <strong>Fecha de fin de ejecución:</strong>
                                      </CTableDataCell>
                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* 17/08/2024 15:02:56 */}
                                        {fechaInicio1}
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* 17/08/2024 15:02:56 */}
                                        {fechaFin1}
                                      </CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                </CTable>

                              </CCol>

                              <CCol xs='12'>
                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-with-2 letra-titulo-2">
                                        <strong>Cliente:</strong>
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                                        {/* <strong>Operador:</strong> */}
                                        <strong>Operador:</strong>
                                      </CTableDataCell>

                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* Empresa XXD */}
                                        {cliente1}
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {operador1}
                                        {/* Juan Perez */}
                                      </CTableDataCell>

                                    </CTableRow>
                                  </CTableBody>
                                </CTable>
                              </CCol>
                              <CCol xs='12'>
                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                                        <strong>Descripción:</strong>
                                      </CTableDataCell>

                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* Empresa XXD Empresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXD */}
                                        {descripcion1}
                                      </CTableDataCell>

                                    </CTableRow>
                                  </CTableBody>
                                </CTable>
                              </CCol>


                            </CRow>
                            <hr></hr>
                          </CCard>
                        </CCol>
                      </CRow>

                    </CTabPanel>
                    <CTabPanel className="py-3" aria-labelledby="profile-tab-pane" itemKey={2}>
                      <CCardBody className='contenedor-table-list container-tabla letra-contenido-2'>

                        <div className='contenedor-scroll-tabla-list' style={{ overflowy: 'auto', height: '370px' }}>
                          {/* <div 
          ref={tableRef} 
          style={{ 
            width: '1500px', // Ancho del contenido de la tabla
            display: 'flex', 
            justifyContent: 'flex-start',
            scrollLeft: scrollOffset // Desplazamiento controlado por JavaScript
          }}
        > */}
                          <CTable striped small align="middle" className="mb-0 ctable-list" hover responsive style={{ width: '100%' }}>
                            <CTableHead className='letra-contenido-2 sticky-header-detail'>
                              <CTableRow>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>ID</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Tipo</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Señaletica</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Latitud</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Longitud</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Carretera asociada</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Sentido</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Frame</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Fecha detección</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Punto KM</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Descripcíon</CTableHeaderCell>
                                <CTableHeaderCell ></CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>
                            <CTableBody className='letra-contenido-3'>
                              {!isLoading && dataProyecto && dataProyecto.senales ? (

                                currentItems.filter((item) => {
                                  const busquedaMinusculas = buscarTexto.toLowerCase();  // Convierte la búsqueda a minúsculas
                                  return (
                                    buscarTexto === '' || // Si el campo de búsqueda está vacío, muestra todos los resultados
                                    camposBusquedaSennal.some(campo => {  // Itera sobre cada campo de búsqueda
                                      const valorCampo = item[campo]?.toString().toLowerCase(); // Obtiene el valor del campo y lo convierte a minúsculas (si existe)
                                      return valorCampo?.includes(busquedaMinusculas); // Verifica si el valor del campo incluye el texto de búsqueda
                                    })
                                  );
                                }).map((item, index) => (
                                  <CTableRow v-for="item in tableItems" key={index}>
                                    <CTableDataCell className="text-center">
                                      <div>{item.id}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div>{item.tipo}</div>
                                      <div className="small text-body-secondary text-nowrap">
                                        {/* <span>{item.user.new ? 'New' : 'Recurring'}</span> | Registered:{' '} */}
                                        {/* {item.user.registered} */}
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                      {/* <CIcon size="xl" icon={item.country.flag} title={item.country.name} /> */}
                                      <div>{item.tipo_especifico}</div>
                                    </CTableDataCell>

                                    <CTableDataCell className="text-center">
                                      {/* <CIcon size="xl" icon={item.payment.icon} /> */}
                                      <div>{item.latitud}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                      <div>{item.longitud}</div>
                                    </CTableDataCell>

                                    <CTableDataCell>
                                      <div>{item.carretera_asociada}</div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>

                                    <CTableDataCell>
                                      <div>{item.sentido_carril}</div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div><CAlertLink className="cursor-pointer" onClick={() => handleVerImagen(item.url_frame)}>
                                        {item.id_frame}
                                      </CAlertLink></div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div>{item.fecha_captura}</div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div className="d-flex justify-content-between text-nowrap">
                                        {/* <div className="fw-semibold">{item.usage.value}%</div> */}
                                        <div className="ms-3">
                                          {/* <small className="text-body-secondary">{item.usage.period}</small> */}
                                        </div>
                                      </div>
                                      {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <CDropdown variant="btn-group">
                                        <CDropdownToggle className='dropdown-toggle-list btn-dropdown-list' color="white"><CIcon icon={cilOptions} style={{ '--ci-primary-color': 'black' }} /></CDropdownToggle>
                                        <CDropdownMenu className='dropdown-menu-list'>
                                          <CDropdownItem className="cursor-pointer" onClick={() => handleEditSennal(index, item.id)}>Editar</CDropdownItem>
                                        </CDropdownMenu>
                                      </CDropdown>
                                    </CTableDataCell>
                                  </CTableRow>
                                ))

                              ) : (
                                <div>Cargando datos...</div> // Muestra un indicador de carga
                              )}
                            </CTableBody>
                          </CTable>
                        </div>

                        {/* </div> */}
                      </CCardBody>
                      {/* Paginación */}
                      {currentItems.length > 0 && ( // Mostrar solo si hay elementos
                        <div className='center'>
                          <CPagination aria-label="Page navigation example" className="cursor-pointer">
                            {/* Botón "Anterior" */}
                            <CPaginationItem
                              aria-label="Previous"
                              disabled={currentPage === 1}
                              onClick={() => handlePageClick(currentPage - 1)}
                            >
                              <span aria-hidden="true">«</span>
                            </CPaginationItem>

                            {/* Renderizar números de página visibles */}
                            {visiblePages.map((pageNumber) => (
                              <CPaginationItem
                                key={pageNumber}
                                active={pageNumber === currentPage}
                                onClick={() => handlePageClick(pageNumber)}
                              >
                                {pageNumber}
                              </CPaginationItem>
                            ))}

                            {/* Mostrar puntos suspensivos si hay más páginas */}
                            {currentPage < totalPages - 2 && (
                              <CPaginationItem disabled>
                                ...
                              </CPaginationItem>
                            )}

                            {/* Mostrar la última página si no está visible */}
                            {currentPage < totalPages - 1 && (
                              <CPaginationItem onClick={() => handlePageClick(totalPages)}>
                                {totalPages}
                              </CPaginationItem>
                            )}

                            {/* Botón "Siguiente" */}
                            <CPaginationItem
                              aria-label="Next"
                              disabled={currentPage === totalPages}
                              onClick={() => handlePageClick(currentPage + 1)}
                            >
                              <span aria-hidden="true">»</span>
                            </CPaginationItem>
                          </CPagination>
                        </div>
                      )}
                      {/* Fin de la paginación */}
                    </CTabPanel>
                    <CTabPanel className="py-3" aria-labelledby="profile-tab-pane" itemKey={3}>
                      <CCardBody className='contenedor-table-list container-tabla letra-contenido-2'>

                        <div className='contenedor-scroll-tabla-list' style={{ overflowy: 'auto', height: '370px' }}>
                          {/* <div 
          ref={tableRef} 
          style={{ 
            width: '1500px', // Ancho del contenido de la tabla
            display: 'flex', 
            justifyContent: 'flex-start',
            scrollLeft: scrollOffset // Desplazamiento controlado por JavaScript
          }}
        > */}
                          <CTable striped small align="middle" className="mb-0 ctable-list" hover responsive style={{ width: '100%' }}>
                            <CTableHead className='letra-contenido-2 sticky-header-detail'>
                              <CTableRow>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>ID</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Tipo</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Severidad</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Area</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Latitud</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Longitud</CTableHeaderCell>

                                <CTableHeaderCell className='letra-contenido-2 fgh'>Carretera asociada</CTableHeaderCell>

                                <CTableHeaderCell className='letra-contenido-2 fgh'>Sentido</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Frame</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Fecha detección</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Punto KM</CTableHeaderCell>
                                <CTableHeaderCell ></CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>
                            <CTableBody className='letra-contenido-3'>
                              {dataProyecto && dataProyecto.fisuras ? (

                                currentItemsFisuras.filter((item) => {
                                  const busquedaMinusculas = buscarTexto.toLowerCase();  // Convierte la búsqueda a minúsculas
                                  return (
                                    buscarTexto === '' || // Si el campo de búsqueda está vacío, muestra todos los resultados
                                    camposBusqueda.some(campo => {  // Itera sobre cada campo de búsqueda
                                      const valorCampo = item[campo]?.toString().toLowerCase(); // Obtiene el valor del campo y lo convierte a minúsculas (si existe)
                                      return valorCampo?.includes(busquedaMinusculas); // Verifica si el valor del campo incluye el texto de búsqueda
                                    })
                                  );
                                }).map((item, index) => (

                                  // {/* {dataProyecto.fisuras.map((item, index) => ( */}
                                  <CTableRow v-for="item in tableItems" key={index}>
                                    <CTableDataCell className="text-center">
                                      <div>{item.id}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div>{item.tipo}</div> */}
                                      <div>{item.tipo}</div>
                                      <div className="small text-body-secondary text-nowrap">
                                        {/* <span>{item.user.new ? 'New' : 'Recurring'}</span> | Registered:{' '} */}
                                        {/* {item.user.registered} */}
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                      {/* <CIcon size="xl" icon={item.country.flag} title={item.country.name} /> */}
                                      <div>{item.severidad}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="d-flex justify-content-between text-nowrap"> */}
                                      {/* <div className="fw-semibold">{item.usage.value}%</div> */}
                                      <div>{item.area}</div>
                                      {/* </div> */}
                                      {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                      {/* <CIcon size="xl" icon={item.payment.icon} /> */}
                                      <div>{item.latitud}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                      <div>{item.longitud}</div>
                                    </CTableDataCell>

                                    <CTableDataCell>
                                      <div>{item.carretera_asociada}</div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>

                                    <CTableDataCell>
                                      <div>{item.sentido_carril}</div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}

                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                      <div><CAlertLink className="cursor-pointer" onClick={() => handleVerImagen(item.url_frame)}>
                                        {item.id_frame}
                                      </CAlertLink></div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div>{item.fecha_captura}</div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <CDropdown variant="btn-group">
                                        <CDropdownToggle className='dropdown-toggle-list btn-dropdown-list' color="white"><CIcon icon={cilOptions} style={{ '--ci-primary-color': 'black' }} /></CDropdownToggle>
                                        <CDropdownMenu className='dropdown-menu-list'>
                                          <CDropdownItem className="cursor-pointer" onClick={() => handleEditDanno(index, item.id)}>Editar</CDropdownItem>
                                        </CDropdownMenu>
                                      </CDropdown>
                                    </CTableDataCell>
                                  </CTableRow>
                                ))

                              ) : (
                                <div>Cargando datos...</div> // Muestra un indicador de carga
                              )}
                            </CTableBody>
                          </CTable>
                        </div>
                        {/* </div> */}
                      </CCardBody>
                      {/* Paginación */}
                      {currentItemsFisuras.length > 0 && ( // Mostrar solo si hay elementos
                        <div className='center'>
                          <CPagination aria-label="Page navigation example" className="cursor-pointer">
                            {/* Botón "Anterior" */}
                            <CPaginationItem
                              aria-label="Previous"
                              disabled={currentPageFisuras === 1}
                              onClick={() => handlePageClickFisuras(currentPageFisuras - 1)}
                            >
                              <span aria-hidden="true">«</span>
                            </CPaginationItem>

                            {/* Renderizar números de página visibles */}
                            {visiblePagesFisuras.map((pageNumberFisuras) => (
                              <CPaginationItem
                                key={pageNumberFisuras}
                                active={pageNumberFisuras === currentPageFisuras}
                                onClick={() => handlePageClickFisuras(pageNumberFisuras)}
                              >
                                {pageNumberFisuras}
                              </CPaginationItem>
                            ))}

                            {/* Mostrar puntos suspensivos si hay más páginas */}
                            {currentPageFisuras < totalPagesFisuras - 2 && (
                              <CPaginationItem disabled>
                                ...
                              </CPaginationItem>
                            )}

                            {/* Mostrar la última página si no está visible */}
                            {currentPageFisuras < totalPagesFisuras - 1 && (
                              <CPaginationItem onClick={() => handlePageClickFisuras(totalPagesFisuras)}>
                                {totalPagesFisuras}
                              </CPaginationItem>
                            )}

                            {/* Botón "Siguiente" */}
                            <CPaginationItem
                              aria-label="Next"
                              disabled={currentPageFisuras === totalPagesFisuras}
                              onClick={() => handlePageClickFisuras(currentPageFisuras + 1)}
                            >
                              <span aria-hidden="true">»</span>
                            </CPaginationItem>
                          </CPagination>
                        </div>
                      )}
                      {/* Fin de la paginación */}
                    </CTabPanel>
                  </CTabContent>
                </CTabs>
              </div>
            </CCard>
          </div>
        </div>

      </CRow>
      <CModal
        alignment="center"
        scrollable
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"

      >
        <CModalContent>
          <CModalHeader style={{ '--cui-modal-header-border-color': 'white' }}>
            <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Edición de Señal</CModalTitle>
          </CModalHeader>
          <CModalBody>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={12}>
                  <CFormLabel htmlFor="tiposenal">Tipo</CFormLabel>
                  <CTooltip className='padding-tooltip'
                    content="Señalización Global (CLASS 1)"
                  // trigger={['hover', 'focus']}
                  >
                    <span className="d-inline-block padding-tooltip" tabIndex={0}>
                      <CButton className="btn-round-small">i</CButton>
                    </span>
                  </CTooltip>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="tiposenal" placeholder="Ingrese tipo" value={tipoSenal} onChange={(e) => setTipoSenal(e.target.value)} />
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="senaleticadetectada">Señaletica Detectada</CFormLabel>
                  <CTooltip className='padding-tooltip'
                    content="Señalización Tipo (CLASS 2)"
                  // trigger={['hover', 'focus']}
                  >
                    <span className="d-inline-block padding-tooltip" tabIndex={0}>
                      <CButton className="btn-round-small">i</CButton>
                    </span>
                  </CTooltip>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="senaleticadetectada" placeholder="Ingrese señaletica" value={senaleticaDetectada} onChange={(e) => setSenaleticaDetectada(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormSelect size="sm"
                    className='letra-contenido-modal-input'
                    label="Señaletica Reclasificada"
                    value={senaleticaReclasificada}
                    onChange={(e) => setSenaleticaReclasificada(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione</option>
                    <option className='fuente-letra-oscura' value="reglamentaria">Reglamentaria</option>
                    <option className='fuente-letra-oscura' value="preventiva">Prioritaria</option>
                    <option className='fuente-letra-oscura' value="informativa">Informativa</option>
                    <option className='fuente-letra-oscura' value="mojon_kilometrico">Mojón Kilométrico</option>
                    <option className='fuente-letra-oscura' value="poste_sos_emergencia_140">Poste SOS, Emergencia 140</option>
                    <option className='fuente-letra-oscura' value="estructuras">Estructuras</option>
                    <option className='fuente-letra-oscura' value="linea_de_carril">Linea de carril</option>
                    <option className='fuente-letra-oscura' value="linea_de_borde">Linea de Borde</option>
                    <option className='fuente-letra-oscura' value="flecha_simple_curvas_y_combinadas">Flecha Simple, Curvas y Combinadas</option>
                    <option className='fuente-letra-oscura' value="pictoramas">Pictoramas</option>
                    <option className='fuente-letra-oscura' value="defensas_metalicas_new_jersey">Defensas metálicas, New Jersey</option>
                    <option className='fuente-letra-oscura' value="iluminacion">Iluminación</option>
                    <option className='fuente-letra-oscura' value="baches">Baches</option>
                    <option className='fuente-letra-oscura' value="otra">Otra</option>
                  </CFormSelect>
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="descripciondetectada">Descripción Detectada</CFormLabel>
                  <CTooltip className='padding-tooltip'
                    content="Señalización Descripción (CLASS 5)"
                  // trigger={['hover', 'focus']}
                  >
                    <span className="d-inline-block padding-tooltip" tabIndex={0}>
                      <CButton className="btn-round-small">i</CButton>
                    </span>
                  </CTooltip>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="descripciondetectada" placeholder="Fuera de uso temporalmente" value={descripcionDetectada} onChange={(e) => setDescripcionDetectada(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormSelect size="sm"
                    className='letra-contenido-modal-input'
                    label="Descripción Detectada Reclasificada"
                    value={descripcionReclasificada}
                    onChange={(e) => setDescripcionReclasificada(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione</option>
                    <option className='fuente-letra-oscura' value="no_avanzar">No avanzar</option>
                    <option className='fuente-letra-oscura' value="no_circular_automoviles">No circular Automóviles</option>
                    <option className='fuente-letra-oscura' value="prohibicion_de_cambiar_de_carril">Prohibición de cambiar de carril</option>
                    <option className='fuente-letra-oscura' value="velocidad_maxima_20">Velocidad maxima 20 km/h</option>
                  </CFormSelect>
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={12}>
                  <CFormLabel htmlFor="observacionessenal">Observaciones</CFormLabel>
                  <CFormTextarea
                    className='letra-contenido-modal-input'
                    id="observacionessenal"
                    placeholder="Escribe comentarios u observaciones"
                    value={observacionesSenal}
                    onChange={(e) => setObservacionesSenal(e.target.value)}
                    rows={4}
                  ></CFormTextarea>
                </CCol>


              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="latitudsenal">Latitud</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="latitudsenal" placeholder="Ingrese latitud" value={latitudSenal} onChange={(e) => setLatitudSenal(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="longitudsenal">Longitud</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="longitudsenal" placeholder="Ingrese longitud" value={longitudSenal} onChange={(e) => setLongitudSenal(e.target.value)} />
                </CCol>
              </CRow>
            </div>
            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="carreteraasociadasenal">Carretera asociada</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="carreteraasociadasenal" placeholder="Ingrese carretera" value={carreteraAsociadaSenal} onChange={(e) => setCarreteraAsociadaSenal(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="fechadeteccionsenal">Fecha de detección</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="fechadeteccionsenal" placeholder="Ingrese fecha" value={fechaDeteccionSenal} onChange={(e) => setFechaDeteccionSenal(e.target.value)} />
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="puntokmsenal">Punto KM</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="puntokmsenal" placeholder="Ingrese km" value={puntokmSenal} onChange={(e) => setPuntokmSenal(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="sentidosenal">Sentido</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="sentidosenal" placeholder="Ingrese sentido" value={sentidoSenal} onChange={(e) => setSentidoSenal(e.target.value)} />
                </CCol>
              </CRow>
            </div>

          </CModalBody>
          <CModalFooter style={{ '--cui-modal-footer-border-color': 'white' }}>
            <CButton className='btn-salir-footer' onClick={() => setVisible(false)}>
              Cancelar
            </CButton>
            <CButton className='btn-next-footer' onClick={() => handleUpdateSennal()}>Guardar</CButton>
          </CModalFooter>
        </CModalContent>
      </CModal>

      <CModal
        alignment="center"
        scrollable
        visible={visible2}
        onClose={() => setVisible2(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalContent >
          <CModalHeader style={{ '--cui-modal-header-border-color': 'white' }}>
            <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Edición de Daño</CModalTitle>
          </CModalHeader>
          <CModalBody>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="tipodano">Tipo</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="tipodano" placeholder="Ingrese tipo" value={tipoDano} onChange={(e) => setTipoDano(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="area">Area</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="area" placeholder="Ingrese area" value={area} onChange={(e) => setArea(e.target.value)} />
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="severidad">Severidad</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="severidad" placeholder="Ingrese Severidad" value={severidad} onChange={(e) => setSeveridad(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormSelect size="sm"
                    className='letra-contenido-modal-input'
                    label="Severidad Reclasificada"
                    value={severidadReclasificada}
                    onChange={(e) => setSeveridadReclasificada(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione</option>
                    <option className='fuente-letra-oscura' value="T02">T2</option>
                    <option className='fuente-letra-oscura' value="T04">T4</option>
                    <option className='fuente-letra-oscura' value="T06">T6</option>
                    <option className='fuente-letra-oscura' value="T08">T8</option>
                    <option className='fuente-letra-oscura' value="T10">T10</option>
                  </CFormSelect>
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={12}>
                  <CFormLabel htmlFor="observacionesdano">Observaciones</CFormLabel>
                  <CFormTextarea
                    className='letra-contenido-modal-input'
                    id="observacionesdano"
                    placeholder="Escribe comentarios u observaciones"
                    value={observaciones}
                    onChange={(e) => setObservaciones(e.target.value)}
                    rows={4}
                  ></CFormTextarea>
                </CCol>


              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="latituddano">Latitud</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="latituddano" placeholder="Ingrese latitud" value={latitudDano} onChange={(e) => setLatitudDano(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="longituddano">Longitud</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="longituddano" placeholder="Ingrese longitud" value={longitudDano} onChange={(e) => setLongitudDano(e.target.value)} />
                </CCol>
              </CRow>
            </div>
            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="carreteraasociadadano">Carretera asociada</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="carreteraasociadadano" placeholder="Ingrese carretera" value={carreteraAsociadaDano} onChange={(e) => setCarreteraAsociadaDano(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="fechadetecciondano">Fecha de detección</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="fechadetecciondano" placeholder="Ingrese fecha" value={fechaDeteccionDano} onChange={(e) => setfechaDeteccionDano(e.target.value)} />
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="puntokmDano">Punto KM</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="puntokmDano" placeholder="Ingrese km" value={PuntokmDano} onChange={(e) => setPuntokmDano(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="sentidoDano">Sentido</CFormLabel>
                  <CFormInput disabled className='letra-contenido-modal-input' size="sm" id="sentidoDano" placeholder="Ingrese sentido" value={sentidoDano} onChange={(e) => setsentidoDano(e.target.value)} />
                </CCol>
              </CRow>
            </div>

          </CModalBody>
          <CModalFooter style={{ '--cui-modal-footer-border-color': 'white' }}>
            <CButton className='btn-salir-footer' onClick={() => setVisible2(false)}>Cancelar</CButton>
            <CButton className='btn-next-footer' onClick={() => handleUpdateDanno()}>Guardar</CButton>
          </CModalFooter>
        </CModalContent>
      </CModal>
      <CModal
        className=''
        alignment="center"
        visible={visible3}
        onClose={() => setVisible3(false)}
        aria-labelledby="VerticallyCenteredExample"
        size="lg"
      >
        <CModalBody className=''>
          <CCard style={{ width: '48rem', height: '28.5rem' }}>
            <div style={{ position: 'relative' }}>
              {/* <CCardImage orientation="top" src="https://cdn.pixabay.com/photo/2015/07/27/19/10/road-863126_960_720.jpg" width="1000" height="455" /> */}
              <CCardImage orientation="top" src={urlFrame} width="1000" height="455" />
              <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '40px' }}>
                <CIcon icon={cilCloudDownload} title="Download file" style={{ '--ci-primary-color': 'white' }}
                  onClick={() => handleDownloadImage(urlFrame)} />
              </button>
              <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                <CIcon icon={cilCompress} style={{ '--ci-primary-color': 'white' }} onClick={() => setVisible3(!visible3)} />
              </button>
            </div>

          </CCard>
        </CModalBody>
      </CModal>

      <CModal
        alignment="center"
        visible={confirmar}
        onClose={() => setConfirmar(false)}
        aria-labelledby="VerticallyCenteredExample"
        size='sm'
      >
        <CModalContent>
          <CModalHeader style={{ '--cui-modal-header-border-color': 'white' }}>
            <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Cargar Datos: Id. {id}</CModalTitle>
          </CModalHeader>
          <CModalBody className='letra-contenido-modal'>
            <center> <strong>Detalles del Proyecto: </strong><br></br> {nameProyectoDetalles}</center>
          </CModalBody>
          <CModalFooter style={{ '--cui-modal-footer-border-color': 'white', flexDirection: 'column' }}>
            {/* <CButton className='btn-salir-footer' onClick={() => setConfirmar(false)}>
              Cancelar
            </CButton> */}
            <div tabIndex="0" onClick={() => { setConfirmar(false) }}>
              <CButton className='btn-next-footer-model' ref={buttonRef} onClick={handlefind}>Aceptar</CButton>
            </div>
          </CModalFooter>
        </CModalContent>
      </CModal>
      {isLoading && ( // Mostrar el spinner solo si isLoading es true
        <div className="loading-overlay">
          <CSpinner color="primary" />
        </div>
      )}

      {showSuccessToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-exito"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Registro insertado correctamente.</CToastBody>
            <CToastClose onClick={handleCloseToast} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}

      {showErrorToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-error"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Ha ocurrido un Error.</CToastBody>
            <CToastClose onClick={handleCloseToastError} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}
    </>
  );
}

function Map() {
  const map = useMap();
  useMapEvents({
    click: (e) => {
      console.log('Click en:', e.latlng);
      map.panTo(e.latlng);
    }
  });
  return null;
}

